export const industrialBuildings = [
  {
    id: 1,
    name: "Obiekt na sprzedaż",
    thumb: "/images/photos/budownictwo1.jpg",
    images: [
      "/images/photos/budownictwo_przemyslowe1.jpg",
      "/images/photos/budownictwo_przemyslowe2.jpg",
      "/images/photos/budownictwo_przemyslowe3.jpg",
      "/images/photos/budownictwo_przemyslowe4.jpg"
    ],
    description: [
      'W znakomitej lokalizacji na skrzyżowaniu drogi krajowej nr 44 oraz wojewódzkiej 928 oferujemy do wynajmu obiekt handlowo-usługowy o powierzchni 700m2 na parterze oraz 500 m2 biur na piętrze do dowolnej aranżacji. Parter o przeznaczeniu usługowym o wysokości 4m pozwoli zaaranżować przestrzeń w celach magazynowych, handlowych lub usługowych. Ciekawa zabudowa pozwoli na stworzenie przestrzeni, która będzie wizytówką dla najemcy. Powierzchnie biurowe z pięknym widokiem na panoramę Mikołowa można przygotować zgodnie z potrzebami klienta. W otoczeniu budynku, a także w podziemiu znajdują się parkingi oraz plac składowy.',
    ],
  },
];